// JavaScript Document

// Scripts written by Jordan @ Weblinx, Inc.

// vendor

import jQuery from "jquery";
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import "bootstrap";

// custom

function checkScreenSize() {
    const newWindowWidth = jQuery(window).width();
    if (newWindowWidth <= 991) {
        jQuery(".mobile-top-menu").appendTo(".menu-menu-1-container ul");
    }
}

jQuery(document).ready(function () {
    checkScreenSize();

    jQuery("#tabs-nav li:first-child").addClass("active");
    jQuery(".tab-content").hide();
    jQuery(".tab-content:first").show();

    // Click function
    jQuery("#tabs-nav li").on("click", function () {
        jQuery("#tabs-nav li").removeClass("active");
        jQuery(this).addClass("active");
        jQuery(".tab-content").hide();

        const activeTab = jQuery(this).find("a").attr("href");
        jQuery(activeTab).fadeIn();
        return false;
    });

    jQuery(".menu-toggle").on("click", function () {
        jQuery("body").toggleClass("menu-open");
        jQuery("body").toggleClass("contact-show");
    });

    let mainSwiper;

    if (document.querySelectorAll(".event-slider .swiper-slide").length > 1) {
        mainSwiper = new Swiper(".event-slider", {
            modules: [
                Pagination,
                Autoplay,
            ],
            slidesPerView: 1,
            spaceBetween: 20,
            loop: true,
            loopFillGroupWithBlank: true,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            keyboard: {
                enabled: true,
            },
            pagination: {
                el: ".swiper-pagination",
            }
        });
    } else if (document.querySelectorAll(".event-slider .swiper-slide").length > 0) {
        console.log("eventSwiper has just one item.");
    } else {
        console.log("eventSwiper does not exist.");
    }

    let bookSwiper;

    if (document.querySelectorAll(".book-slider .swiper-slide").length > 1) {
        bookSwiper = new Swiper(".book-slider", {
            modules: [
                Navigation,
                Autoplay
            ],
            slidesPerView: 1,
            spaceBetween: 15,
            loop: true,
            autoplay: {
                delay: 6000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            keyboard: {
                enabled: true,
            },
            navigation: {
                nextEl: ".book-swiper-next",
                prevEl: ".book-swiper-prev",
            },
            breakpoints: {
                300: {
                    slidesPerView: 3
                },
                767: {
                    slidesPerView: 6
                },
                1024: {
                    slidesPerView: 8
                }
            }
        });
    } else if (document.querySelectorAll(".book-slider .swiper-slide").length > 0) {
        console.log("bookSwiper has just one item.");
    } else {
        console.log("bookSwiper does not exist.");
    }

    jQuery(".swiper-container").on("mouseenter", function () {
        console.log("stop autoplay");

        if (bookSwiper && document.querySelectorAll(".book-slider .swiper-slide").length > 1) {
            bookSwiper.stopAutoplay();
        }

        if (mainSwiper && document.querySelectorAll(".event-slider .swiper-slide").length > 1) {
            mainSwiper.stopAutoplay();
        }
    });

    jQuery(".swiper-container").on("mouseleave", function () {
        console.log("start autoplay");

        if (bookSwiper && document.querySelectorAll(".book-slider .swiper-slide").length > 1) {
            bookSwiper.stopAutoplay();
        }

        if (mainSwiper && document.querySelectorAll(".event-slider .swiper-slide").length > 1) {
            mainSwiper.stopAutoplay();
        }
    });

});
jQuery("button#find-it-btn").on("click", function () {
    // test
    const search_type = jQuery("input[type='radio'][name='radio-group']:checked").val();
    const search = encodeURIComponent(jQuery("#search_input").val());
    const search_key = jQuery("#search_key").val();
    let search_query = jQuery("#search_link").val();
    search_query = search_query.replace("{key}", search_key);
    search_query = search_query.replace("{query}", search);

    if (search_type != "" && search != "") {
        if (search_type == "catalog") {
            window.open(search_query, "_blank");
        } else {
            return true;
        }
    }
    return false;
});

jQuery("#mobile_dropdown_slider").on("change", function () {
    const current_slider = jQuery(this).val();
    jQuery(".custom_home_mobile_slider").hide();
    jQuery(`#${current_slider}`).toggle();
});

jQuery(window).on("resize", function () {
    checkScreenSize();
});
