// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

const CHECKBOXES = document.querySelectorAll(".calCheckBox input[name='hours[]']");

if (CHECKBOXES.length > 0) {
    CHECKBOXES.forEach((checkbox, index) => {
        checkbox.addEventListener("click", () => {
            if (index > 4) {
                CHECKBOXES[index - 4].checked = false;
            }

            setTimeout(() => {
                const CHECKED_BOXES = document.querySelectorAll(".calCheckBox input[name='hours[]']:checked");

                if (CHECKED_BOXES.length > 4) {
                    CHECKED_BOXES.forEach((checkbox, index) => {
                        if (index < CHECKED_BOXES.length - 4) {
                            checkbox.checked = false;
                        }
                    });
                }
            }, 100);
        });
    });
}
