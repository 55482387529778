const FORM = document.querySelector(".bookly-form");

const CHECK_FOR_FIELD = setInterval(() => {
    const FIELD = FORM.querySelector(".bookly-js-user-notes");

    if (FIELD) {
        clearInterval(CHECK_FOR_FIELD);

        // Check if lcn parameter is set in the URL
        const URL_PARAMS = new URLSearchParams(window.location.search);
        const LCN = URL_PARAMS.get("lcn");

        if (LCN) {
            FIELD.value = `${l10n.bookly.lcn}: ${LCN}`;
        }
    }
}, 500);
